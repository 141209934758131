<template>
    <div id="front" class="container-fluid">
        <Toasts v-on:hide-toasts="1 == 1" :toasts="toasts" v-on:close-all="toasts = []"
            v-on:disable-help="[opts.tutorial = false, toasts = []]" />
        <div id="main">
            <div class="row">
                <div id="front-page" class="col-12">
                    <h2 class="d-md-none align-center">Updates</h2>
                    <div v-for="(post, indx) in postsToShow" :key="indx" class="w-100 marg-big">
                        <!-- pagination -->
                        <div class="d-flex justify-between" style="flex-direction:column;">
                            <div class="col-md-10 col-lg-8 col-xl-7 mx-auto book-card pad-huge top-block d-flex pad-big">
                                <!-- pagination left / latest icon -->
                                <a v-if="flags.showingPost !== 0" @click="prevPost()" class="clickable d-block align-center"
                                    style="color:grey; flex-direction:column;">
                                    <span class="fa-stack">
                                        <i class="fa-stack-2x fas fa-square"></i>
                                        <i class="fa-stack-1x fas fa-angle-double-left"></i>
                                    </span>
                                </a>
                                <a v-else class="clickable d-block align-center" style="color:grey; flex-direction:column;">
                                    <span class="fa-stack">
                                        <i class="fa-stack-2x fas fa-square c-purple"></i>
                                        <i class="fa-stack-1x fas fa-thumbtack"></i>
                                    </span>
                                </a>
                                <div class="title-block">
                                    <h2>{{ post.title }}</h2>
                                    <div style="color:grey;">{{ post.date }}</div>
                                </div>
                                <!-- pagination right -->
                                <a @click="nextPost()" class="clickable d-block align-center"
                                    style="color:grey; flex-direction:column;">
                                    <span class="fa-stack">
                                        <i class="fa-stack-2x fas fa-square"></i>
                                        <i class="fa-stack-1x fas fa-angle-double-right"></i>
                                    </span>
                                </a>
                            </div>
                            <div class="post-block book-card col-md-9 col-lg-7 col-xl-6 mx-auto pad-huge marg-small"
                                style="min-height:175px;">
                                <div class="post-content" v-html="post.html"></div>
                            </div>
                        </div>

                    </div>
                    <div class="col-6 col-md-4 col-lg-3 mx-auto align-center">
                        <img id="site-img" :dark="img.dark" :light="img.light" :src="img.light"
                            style="width: 100%; border-radius:12px" />
                        <p>Site styling is WIP</p>
                    </div>
                </div>
                <!--EOR-->
            </div>
        </div>
    </div>
</template>

<script>
import C2 from '@/assets/common'
import { SS } from '@/assets/constants'
import Posts from './posts'
import Toasts from '../Shared/Toasts.vue'
import getSavedOptions from '@/assets/js/getSavedOptions'
import TOAST_CONFIG from '@/assets/js/toastsConfig/blog'

const DarkImg = require('@/assets/images/site/shirakami.png')
const LightImg = require('@/assets/images/site/niiiitoooon.jpg')

const {
    SendUserAlert,
    UseAPI,
} = C2

export default {
    name: 'Blog',
    components: {
        Toasts
    },
    computed: {
        postsToShow() {
            return this.posts.slice(this.flags.showingPost, this.flags.showingPost + this.flags.postsToShow)
        },
        $postDatePrev() {
            let index = null
            this.flags.showingPost + 1 >= this.posts.length
                ? index = 0
                : index = this.flags.showingPost + 1
            if (index === 0) {
                return 'Latest'
            } if (index && this.posts[index] && 'date' in this.posts[index]) {
                return this.posts[index].date.replace(', 2020', '')
            }
            return ''
        },
        $postDateNext() {
            let index = null
            this.flags.showingPost - 1 < 0
                ? index = this.posts.length - 1
                : index = this.flags.showingPost - 1
            if (index === this.posts.length - 1) {
                return 'First'
            } if (index !== undefined && this.posts[index] && 'date' in this.posts[index]) {
                return this.posts[index].date.replace(', 2020', '')
            }
            console.log('returned', index, this.posts[index], 'date' in this.posts[index])
            return ''
        }
    },
    data() {
        return {
            toasts: [],
            possibleToasts: null,
            flags: {
                optionsInitiated: false, // has done initial options setup in created()
                showingPost: 0,
                postsToShow: 1,
                lockrPrefix: 'home',
            },
            posts: Posts,
            img: {
                dark: DarkImg,
                light: LightImg,
                current: LightImg
            },
            opts: {
                tutorial: true,
            }
        }
    },
    methods: {
        nextPost() {
            this.flags.showingPost + 1 >= this.posts.length
                ? this.flags.showingPost = 0
                : this.flags.showingPost += 1
        },
        prevPost() {
            this.flags.showingPost - 1 < 0
                ? this.flags.showingPost = this.posts.length - 1
                : this.flags.showingPost -= 1
        },
        getMedia(newVal) {
            const vm = this
            if (this.posts.length === 0) { return false }
            if ('media' in this.posts[newVal]) {
                const { media } = vm.posts[newVal]
                let slot = 0

                document.querySelectorAll("media").forEach((el) => {
                    const { val } = media[slot]
                    let { caption } = media[slot]
                    if (!caption) caption = ''

                    const template = document.createElement('template')
                    const html = `${val}<div class="w-100 align-center"><sub>${caption}</sub></div>`
                    template.innerHTML = html.trim()

                    console.log('media', media, 'el', template.content.firstChild)
                    el.appendChild(template.content.firstChild)
                    el.attributes.added = true
                    slot += 1
                })
            }
            return true
        },
        saveSettings() {
            console.log('running saveSettings')
            const body = JSON.stringify({
                context: 'home',
                val: JSON.stringify(this.opts)
            })
            if (this.flags.optionsInitiated) {
                UseAPI('/create/user-settings', {
                    method: "PUT",
                    body
                })
                    .then(() => {
                        SendUserAlert('Settings saved to server', 'alert-success')
                        //  remove cookie and refresh, so that it doesn't hold up future page loads
                        SS.remove(SS.USER_SETTINGS_COOKIE)
                        getSavedOptions()
                    })
                    .catch((err) => {
                        SendUserAlert('Error saving user settings to server', 'alert-danger')
                        console.log('Error saving user settings to server', err)
                    })
            }
        },
    },
    watch: {
        'flags.showingPost': {
            handler(newVal, oldVal) {
                const vm = this
                /* if (this.getMedia(newVal) === false) {
                    // happens on first load... posts not loaded for some reason
                    window.setTimeout(() => {
                        this.getMedia(newVal)
                    }, 1000)
                } */
                this.$nextTick(() => {
                    this.getMedia(newVal)
                })
            },
            immediate: true
        },
        opts: {
            handler(arr) {
                if (!SS.get(SS.PUBLIC_API)) this.saveSettings()
            },
            deep: true
        }
    },
    created() {
        console.log('%cBlog created', window.ConsoleStyles.createdComponent, this)
        this.possibleToasts = { ...TOAST_CONFIG }
        const tutorialInit = () => {
            this.toasts.push(this.possibleToasts.intro)
            this.toasts.push(this.possibleToasts.library)
            if (this.$mq === 'md+') this.toasts.push(this.possibleToasts.searchFunctionality)
        }

        const CONTEXT = 'home'
        getSavedOptions(CONTEXT)
            .then((resultingOpts) => {
                console.log('%cgetUserSettings', window.ConsoleStyles.routine, resultingOpts)
                Object.keys(resultingOpts).forEach((aKey) => {
                    this.opts[aKey] = resultingOpts[aKey]
                })
            })
            .catch((result) => {
                if (!SS.get(SS.PUBLIC_API)) {
                    console.log('getUserSettings', result)
                    SendUserAlert(`Failed to fetch user settings: ${result}`, 'alert-warning')
                }
            })
            .then(() => {
                if (this.opts.tutorial) tutorialInit()
                this.flags.optionsInitiated = true
            })
    }
}
</script>

<style lang="sass">
.post-content
    h2
        color: rgba(255, 165, 0, .71)!important
.top-block
    h2
        color: rgba(255, 165, 0, 1)!important
    a
        padding: 0 .5em
        flex: 0 1 1%
        position: relative
    .title-block
        flex: 1 1 1%
        padding: 0 1.5em
        position: relative
    .title-block:after
        content: ''
        position: absolute
        top: 100%
        left: 5%
        right: 5%
        width: auto
        height: 2px
        background-color: orange
.post-block
    padding-top: 2em!important
    padding-bottom: 2em!important
</style>
