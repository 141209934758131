const TOAST_CONFIG = {
    intro: {
        header: 'Introduction',
        body: 'Welcome to <b>Koohi.cafe</b>. This is a website for pre-learning kanji and vocabulary from Japanese media with the goal of creating a smoother first-reading experience.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    library: {
        header: 'Getting Started',
        body: 'In order to learn words through Koohi, you\'ll have to find a vocabulary list to learn from. <b>Start by visiting the <a href="/#/books">library</a> page on the top-left</b>.',
        headerStyle: 'bg-primary',
        bodyStyle: 'bg-light bg-gradient text-dark'
    },
    searchFunctionality: {
        header: 'Not new to Japanese?',
        body: 'If you\'ve already learned Japanese from other sources, you can use various settings to ease your entry into Koohi.<br>1. Wanikani: Before generating a vocabulary list, you can set your Wanikani level to remove already known words.<br>2. Anki/Houhou: You can use the <a href="/#/importer">importer</a> to import words you already known from a csv file. <b>You will need to be logged in for this</b>'
    },
}

export default TOAST_CONFIG
