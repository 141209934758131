const postListHTML = [
    {
        title: 'Possible fixes',
        date: 'October 28, 2023',
        html: `<div>Possible fix to an issue people are having with the review page not loading. The current fix involved removing all uses of jQuery so this is across like 50 instances (aka possible breakage I didn't find).
    <ul>
        <li>Removed jQuery (possible review page fix)</li>
        <li>When in reviews, fixed logs appearing on top of edit modal</li>
    </ul>
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/SI8BIlHOagk" title="NIGHT DANCER / imase┃Raon cover" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Fixes',
        date: 'August 14, 2023',
        html: `<div>Minor fixes. Let me know on Wanikani if anything else is broken.
    <ul>
        <li>Fixed importer always saying "no valid data found"</li>
        <li>Adjusted closing on Lessons -> search bar and dropdown not collapsing as expected. (Trying third-party directive for this)</li>
    </ul>
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/SI8BIlHOagk" title="NIGHT DANCER / imase┃Raon cover" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Back from the Dead',
        date: 'March 7, 2023',
        html: `<div>One of the biggest users of the site messaged me out of the blue and has awakened me from my eternal slumber: to fix a small bug.</div>
    <h2>Change Log</h2>
    <ul>
        <li>CAPS inputs are now acceptable (ex: GMO answered as "GMO"). Before they would be marked incorrect.</li>
    </ul>
    <h2>How's the Rework?</h2>
    <div>Dunno. There's two big issues in front of me: first of all, I want typescript. Problem: It's the most boring thing in the world. Second: I'm not sure what the rework contributes other than being the same thing but better. That's like fine, but not really inspiring to work on for me.</div>
    <div>I have felt inspired lately looking at graphing databases and their application to linguistics. The current mysql status makes it ridiculously hard to create and modify queries. In the rework, I straight up wrote a query generator library because I couldn't handle the complexity.</div>
    <div>On the other hand, matching relationships to relationships in Cypher feels extraordinarily natural. Query writing is a breeze. Joining disparate databases (ex: NINJAL) seems like it should be quite easy.</div>
    <div>Anyway, that's about all I have to say right now.</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/raJe0X_Aqyk" title="【相羽ういは/健屋花那】femme fatale ｢鼓動｣ 歌ってみた #くまいさん【にじさんじ】" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Small Update',
        date: 'September 15, 2022',
        html: `<div>Small update to the lessons. Created a dropdown for the additional actions you could do (next to the search). Possibly fixed some issue with chrome seeming to think the page was reloading whenever you added a word from search (?). New interface for the Add Custom Word and Add Kanji modals on the login page. They're separated into separate things now.</div>
    <div>This is a bit of side-grade right now but lays some groundwork I needed for future updates.</div>
    <h2>What happened to the Overhaul?</h2>
    <div>Not feeling very inspired right now. I feel like that shouldn't stop the entire website from receiving support though. I'm thinking a lot more modular now, besides, so a lot of the stuff that I add to the site now can just be ported over.</div>
    <h2>Next Update</h2>
    <div>This was preliminary work for some search overhauling. I've been studying for N1 so I need some additional search features which will be the focus of the next updates</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/vme3SGv7jQU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'General Update',
        date: 'July 17, 2022',
        html: `<div>Built a GUI for quickly uploading books. Working pretty well.</div>
    <div>12 new books added. Still working on/off on the overhaul.</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/vme3SGv7jQU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Honzuki no Gekokujou',
        date: 'March 15, 2022',
        html: `<div>A Wanikani member extracted and donated volumes 4-12 of Honzuki no Gekokujou so those have been added to the catalog.</div>
    <div><b>Fixed the bug where practice lessons were not updating properly.</b></div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/vme3SGv7jQU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Settings Overhaul',
        date: 'April 28, 2022',
        html: `<div>As an unintended consequence of me fixing the login issues that occured when your token expires, I've had to completely overhaul the way saved user settings are retrieved. This may introduce bugs.</div>
    <h2>Change Log</h2>
    <div>
        <ul>
            <li>Fixed: Newest book on the library was not showing up</li>
            <li>Toasts should properly go away on first page view</li>
            <li>Vocab: if you click "do not show help" on the toasts, it will save. Before, you'd have to generate a list.
            <li>Lesson preloading is dead as a result of easily reproduceable bugs. RIP.
            <li>LOGGED OUT: The "favorite" and "already read" buttons are removed from books. Helps railroad the new user experience</li>
            <li>LOGGED OUT: Settings will be saved within your session. Should help the website feel responsive to actions.</li>
        </ul>
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/vme3SGv7jQU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Bug fixes',
        date: 'April 24, 2022',
        html: `<div>
    Fixed issues with the data preloader causing data on the interface to be out of sync.
    </div>
    <h2>Preloader</h2>
    <ul>
        <li>Will clear the lessons cache after adding or deleting a word on the reader/lists page</li>
        <li>Will clear the lessons cache after visting the lessons page (meaning no longer out of sync when going from quiz -> lessons)</li>
    </ul>
    <h2>Reviews</h2>
    <ul>
        <li>Header visibility should be restored when going from review/quiz -> lessons</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/de1bt5dc2t0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'An actual update',
        date: 'April 20, 2022',
        html: `<div>
    An actual maintenance update.
    </div>
    <h2>Change Log</h2>
    <div>
        <ul>
            <li><b>Fixed Login Issue</b> - the long-standing issue where you show up to the website with an expired login token and it bugs out</li>
            <li><b>Library -> Learn</b> - fixed going to a vocab list directly from the library causing a darkened screen until refresh</li>
            <li><b>General</b> reduced font sizes for japanese text (this is uglier btw xd)</li>
            <li><b>Covers</b> - fixed missing covers for the latests books (?)</li>
        </ul>
    </div>
    <h2>Rework Progress</h2>
    <div>It's going slowly. I need to go learn Nuxt.js for server side rendering so that I can actually get indexed.</div>
    <div>Screenshots
    <ul>
        <li><a target="_blank" href="https://community.wanikani.com/t/weow-koohicafe-a-wk-friendly-srs-300-vocabulary-lists/48703/588?u=raionus">here</a></li>
        <li><a target="_blank" href="https://community.wanikani.com/t/weow-koohicafe-a-wk-friendly-srs-300-vocabulary-lists/48703/598?u=raionus">here</a></li>
        <li><a target="_blank" href="https://community.wanikani.com/t/weow-koohicafe-a-wk-friendly-srs-300-vocabulary-lists/48703/601?u=raionus">here</a></li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/de1bt5dc2t0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: '2022 Overhaul Development',
        date: 'January 6, 2022',
        html: `<div>I have secured a three-day work week for a period of at least three months. During this time I'll be working on the annual Koohi/Floflo overhaul. I've outlined the main plans for it on the Wanikani thread <a href="https://community.wanikani.com/t/weow-koohicafe-a-wk-friendly-srs-300-vocabulary-lists/48703/556?u=raionus">here</a>.</div>
    <h2>First Steps</h2>
    <div>The current plan is to work on the blog and the new API. These will have zero impact on the functionality of the website as you know it but the blog will allow me to drum up traffic to the main application and improves my ability to communicate with the user base, so it's first.</div>
    <div>Since the current iteration of the blog (which I talk to you on now) has zero SEO, it's literally useless for trying to communicate anything of substance (Japanese articles, stats, etc.) because no one will be able to find it. Hence new blog.</div>
    <h2>Integration</h2>
    <div>The current plan for the overhaul is to run the overhaul on a different subdomain (say beta.koohi) or something like that. This will make the overhaul opt in. However, I expect history will repeat itself like when we switched over from Floflo to Koohi and the new website wasn't bug tested enough because people didn't want to modify their existing workflows. I'm writing a lot of automated testing to try and mitigate the inevitable disaster that will occur when it's thrust upon everyone - bug tested or not.</div>
    <div>Hopefully it won't be too bad. Even Floflo -> Koohi wasn't <i>that</i> bad despite the huge scale of the changes.</div>
    <h2>Existing Application</h2>
    <div>I haven't actually received a bug report for Koohi in a while, but that could also be a result of the low traffic it gets from new users due to it <i>literally being invisible on Google due to no SEO</i>. <b>I am aware of a bug with the site getting stupid when your access token expires (the user settings undefined error),</b> however no one has actually complained about it despite it being around for like three months.</div>
    <div>It seems like frequent users have kind of hit the point where they ignore these kind of non-lethal issues so I'm not going to stir the pot unless asked.</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/lpbtw0e3iWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Book Club',
        date: 'October 20',
        html: `
    <div>A new Wanikani book club will begin on November 6th for <a href="https://community.wanikani.com/t/%E3%81%8B%E3%81%8C%E3%81%BF%E3%81%AE%E5%AD%A4%E5%9F%8E-kagami-no-kojou-book-club-starting-november-6th/53156" target="_blank">Kagami no Kojou</a>. Join that if you're interested!</div>
    <div class="col-8 mx-auto"><img src="https://aws1.discourse-cdn.com/wanikanicommunity/original/4X/e/0/8/e0876bd028652d75c37e670af9fe2ac2c680f221.jpeg" /></div>
    <div>You can see some of the words for list here: <a href="https://koohi.cafe/#/list?list=book_kagami_no_kojou" target="_blank">here</a></div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/lpbtw0e3iWE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Break (?)',
        date: 'October 10',
        html: `
    <div>I'm currently working two jobs and it's impossible for me to get meaningful updates to this website  for the moment.</div>
    <div><b>Starting at the end of December</b>, I'll be vastly reducing my IRL job work hours. This will give me time to improve my skills by taking courses and doing projects. This will in turn translate to me doing huge updates to this website because I use this as my capstone every single time.</div>
    <div>Until then, I don't have time to work here. <b>I will continue to do content updates but no feature updates for now</b>. If you encounter bugs, you can still drop it off in the Wanikani threads and I'll fix it now depending on the severity.</div>
    <div>I don't know what I have planned for the post-December days, but it'll be big.</div>
    <div>Thank you for understanding and for supporting me over the years. I've been slacking but I'll come to repay it soon. -Raionus</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/F0CnOI5d1DI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Break (?)',
        date: 'October 10',
        html: `
    <div>I'm currently working two jobs and it's impossible for me to get meaningful updates to this website  for the moment.</div>
    <div><b>Starting at the end of December</b>, I'll be vastly reducing my IRL job work hours. This will give me time to improve my skills by taking courses and doing projects. This will in turn translate to me doing huge updates to this website because I use this as my capstone every single time.</div>
    <div>Until then, I don't have time to work here. <b>I will continue to do content updates but no feature updates for now</b>. If you encounter bugs, you can still drop it off in the Wanikani threads and I'll fix it now depending on the severity.</div>
    <div>I don't know what I have planned for the post-December days, but it'll be big.</div>
    <div>Thank you for understanding and for supporting me over the years. I've been slacking but I'll come to repay it soon. -Raionus</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/P694QbGGTLY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Internal Improvements Pt. 1',
        date: 'September 12',
        html: `
    <div>The last two weeks were spent doing internal improvements to the website that should translate to a better user experience overall.</div>
    <h2>Library Page</h2>
    <div>I have completely redone the code for a lot of the library page. This means 1) filtering, sorting, series should be less intensive on your device. 2) There have been some improvements/changes to the controls. See the change log.</div>
    <h2>Console Styling</h2>
    <div>Some time was spent creating a utility for styling javascript console information. This should make it easier for me to read/find things, but it should also make it possible for people who didn't program it to spot things. I'm not counting on this, just thought I'd mention it.</div>
    <h2>Preload API</h2>
    <div>One of the big projects I've been working on was a preloading api inspired by instantclick.io. Basically, what happens is normally you click a link (ex: lessons page), the page switches over, it loads an empty page, then it calls the api, then fills it with data.</div>
    <div>The new api changes, found on the LESSONS and LIBRARY page will now start the api load when your mouse clicks DOWN on the link initially, instead of waiting for a page swap and then the first paint before starting the call. Based on the <a href="http://instantclick.io/click-test" target="_blank">instantclick.io click test</a> This leads to a MINIMUM of probably 200ms improvement, but probably more like half a second or more in practice. The results of said api calls are also stored locally with an expiration so navigating back to eligible pages should be instant.</div>
    <div>As with all updates, these two projects might've introduced some bugs, so feel free to communicate with me on Wanikani if you have concerns.</div>
    <h2>Change Log</h2>
    <h2>Library</h2>
    <ul>
    <li>Redid large portions of this</li>
    <li>The /books page is eligigble for preloading</li>
    <li>Ordering is now part of the main interface instead of the sidebar</li>
    <li>Multiple filters can be used at the same time (and operator in an X Or Y criteria fashion)</li>
    <li>Mediums can be enabled/disabled individually</li>
    <li>FIX: series collapses should collapse more smartly. They are disabled on unreleased books, searches, or when most filters are enabled</li>
    <li>Improved styling on cover badges (they look more like stickers now) to improve visibility</li>
    <li>Modified "owned book" icon to be next to the medium instead of in the badges</li>
    <li>Toggling filters/mediums/ordering is much faster</li>
    </ul>
    <h2>General</h2>
    <ul>
        <li>Preloader api now active on /lessons and /books</li>
    </ul>
    <h2>Lessons</h2>
    <ul>
        <li>As a result of using the preloader api, should load faster ~.5s</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/P694QbGGTLY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Revamped SRS Options List',
        date: 'August 23, 2021',
        html: `
    <div>Still untangling some merge issues. For now, there's a change to the way options in the SRS page are presented. There's also a few new options.</div>
    <h2>Change Log</h2>
    <h2>SRS</h2>
    <ul>
        <li>Redesigned options menu</li>
        <li>Added option to show card notes before you answer</li>
        <li>Added option to show card reference words before you answer</li>
        <li>Added an option to reset options to default</li>
    </ul>
    <h2>General</h2>
    <ul>
        <li>Fixed some dropdown menu issues</li>
        <li>Updated some dependencies (this is not interesting but took a long time).
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/-oN0vm3FY9c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Revamped SRS Options List',
        date: 'August 23, 2021',
        html: `
    <div>Still untangling some merge issues. For now, there's a change to the way options in the SRS page are presented. There's also a few new options.</div>
    <h2>Change Log</h2>
    <h2>SRS</h2>
    <ul>
        <li>Redesigned options menu</li>
        <li>Added option to show card notes before you answer</li>
        <li>Added option to show card reference words before you answer</li>
        <li>Added an option to reset options to default</li>
    </ul>
    <h2>General</h2>
    <ul>
        <li>Fixed some dropdown menu issues</li>
        <li>Updated some dependencies (this is not interesting but took a long time).
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/-oN0vm3FY9c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Weekly Update & Sale',
        date: 'August 2, 2021',
        html: `
    <h2>Monthly Sale</h2>
    <div>The monthly custom list sale is currently underway. If you order between now and the 7th there's 15% off in addition to any bulk discounts you might be getting.
    <br>You can go to the library page and click "custom lists" if you're interested in placing an order.</div>
    <h2>Change Log</h2>
    <h2>General System</h2>
    <ul>
        <li>Created a system for monitoring api times (for finding slow parts of the site)</li>
        <li>Started a system for collecting feature usage (so I know what to focus on)</li>
    </ul>
    <h2>SRS</h2>
    <ul>
        <li>Streaks no longer reset when you undo a wrong answer</li>
        <li>UX: Streak graphic now changes color and size based on the size of your streak</li>
        <li>Mobile: Realigned text input field and buttons</li>
        <li>Fixed autofocus on page load not working</li>
    </ul>
    <h2>Library</h2>
    <ul>
        <li>Fixed mispaces on the mobile</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/-oN0vm3FY9c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Interface Improvements & SALES',
        date: 'July 26, 2021',
        html: `
    <h2>Sales</h2>
    <div>So the huge ass bulk sales I used to have on Floflo are now back in action. You can now theoretically bring the price of a custom order down nearly 33% a unit if you're an absolute madman.</div>
    <div><b>There are now huge bulk discounts available at all times</b>. In addition, <b>cart prices are down 15% between the 1st and 7th of every month</b>. The sales help me keep prices down for you and helps me by consolidating purchases into one period (it is equally hard to put up 1 book vs 15 books).</div>
    <div>Check it out by clicking the "Custom List" book on the library page</div>
    <div class="col-12 col-md-8 mx-auto"><media></media></div>
    <h2>Change Log</h2>
    <div>A number of small improvements to different parts of the interface.</div>
    <h2>Vocabulary Lists</h2>
    <ul>
        <li>Refactored some code</li>
        <li>Added info icons to new option notes</li>
        <li><b>Phonetic matches are now marked as phonetic (near frequency)</b></li>
        <li><b>Phonetic matches have an indicator showing how many individual cards will be created/trashed</b></li>
        <li>The book selector will be greyed out when there are no items to show (all mediums are disabled)</li>
        <li>Mobile: options menu icon (near generate list button) is now aligned properly</li>
    </ul>
    <h2>Word Search</h2>
    <ul>
        <li><b>Added an indicator for status of search: blank (no items), slashed eye (items but not showing), eye (items and showing), loading</b></li>
        <li>This is in response to a bug which sometimes hides the list of items when the user expects it to be open</li>
    </ul>
    <h2>Custom Lists</h2>
    <ul>
        <li>Changes prices for custom lists</li>
        <li>Added a price simulator app</li>
        <li>Simplified and clarified purchase info</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<img src="https://i.gyazo.com/ca1ab7f9116884b4dbe7bfcdfe871762.png"/>`
            },
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/_r0d0gXEKgI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Usually Kana Word Support',
        date: 'July 19, 2021',
        html: `
    <div>Only a small update this weekend. Support for words that are usually kana has been added. You can turn this off on the Reader/Vocab Lists to get the old behavior back. Next week will include additional indicators to clarify behavior.</div>
    <div>Current behavior is that for EXACT matches with multiple entries, adding any entry with the uk tag will disable readings. For PHONETIC matches, any card(s) added that have a uk tag will be added with readings disabled. Behavior may be subject to change.</div>
    <h2>Reader</h2>
    <ul><li>Added support for usually kana words</li></ul>
    <h2>Vocabulary Lists</h2>
    <ul><li>Added support for usually kana words</li></ul>
    <h2>Lessons</h2>
    <ul><li>Fixed a typo in custom card creation</li></ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/v55-cRHSNrQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'QoL',
        date: 'July 11, 2021',
        html: `
    <div>Still working for this weekend but I needed to upload my changes to get the cover up anyway.</div>
    <h2>Change Log</h2>
    <h2>General</h2>
        <ul>
            <li>For transparency, added an initial loading indicator to all segments of the website</li>
            <li>In the heading, the Reader and dark/light mode buttons highlight in a way consistent with the rest of the links</li>
            <li>Fix loading indicator color on light mode</li>
            <li>"Disable Tutorial" and "Close all toasts" now appended to last toast, instead of cluttering screen with an additional toast</li>
            <li>Toasts should no longer float slightly offscreen (mobile)</li>
            <li>Some toasts will not appear if on a small screen-size</li>
        </ul>
    <h2>Books</h2>
        <ul>
            <li>Finally added a default cover image</li>
        </ul>
    <h2>Reader</h2>
        <ul>
            <li>Changed "generate" button styling to make it more clear what item is selected</li>
            <li>First time toggling a medium in the search will unselected all other mediums</li>
        </ul>
    <h2>Vocab Lists</h2>
        <ul>
            <li>First time toggling a medium in the search will unselected all other mediums</li>
        </ul>
    <h2>Data</h2>
        <ul>
            <li>Removed burned items from kanji/vocab and added as a new dark black color</li>
            <li>Visual adjustments</li>
        </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/q5e0C6xizVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Important Bug Fixes!',
        date: 'June 28, 2021',
        html: `
    <div>Managed to figure out a few of the persistent bugs that were bothering people</div>
    <h2>Change Log</h2>
    <h2>API</h2>
        <ul>
            <li>Search no longer throws a (non-fatal) error when zero items are returned from a search</li>
            <li>Reviews counts are now updated correctly when going from Review > Lessons</li>
            <li>Xp visual calculations should be fixed</li>
        </ul>
    <h2>Books</h2>
        <ul>
            <li>Unknown words on books are now counted correctly</li>
        </ul>
    <h2>Exporter</h2>
        <ul>
            <li>Table text is now white (readable) on dark mode</li>
        </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/q5e0C6xizVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Actual Content',
        date: 'June 19, 2021',
        html: `
    <div><b>I am experimenting with affiliate links to Amazon. If you are interested in supporting the site, go use them before you purchase. To access them, go to the books page, click a book so the modal pops up, and then follow the link to amazon.</b></div>
    <div>I've implemented some new systems this week. One of these is the automatic collapsing of series into a single book. I felt the need to implement this because series spam was getting too prevalent in some cases (Imouto Sae Ireba Ii). When you click it, it will take you to the series. When you leave, it will attempt to scroll back to your original place. Aside from that, check the change log.</div>
    <h2>Change Log</h2>
    <h2>Books</h2>
    <ul>
        <li>Series Collapse now</li>
        <li>Added a desktop wide layout for book modals</li>
        <li>Exiting a meta category (series, etc.) will take you back to your previous spot</li>
        <li>While calculating unknown words: process will not restart when exiting page (you come back and its done)</li>
        <li>Importing/Trimming will clear the unknown words cache</li>
        <li>Options: wk level will not break if you click "cancel" on the prompt</li>
        <li>Refractored some components</li>
    </ul>
    <h2>Lessons</h2>
    <ul>
        <li>Added hotkeys (left/right, a/d) for navigating pages</li>
    </ul>
    <h2>Lists</h2>
    <ul>
        <li>Added arrowkey support (left/right) for navigating pages</li>
    </ul>
    <h2>Reader</h2>
    <ul>
        <li>Added hotkeys (left/right, a/d) for navigating pages</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/MRi0Z6CULYI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'General Update',
        date: 'June 7, 2021',
        html: `
    <div>A general update here. First of all 1) I've discovered the issue with the book counting. It's based on a data table based off the dictionary, which has some corrupted data. I think that updating the dictionary with a fresh copy will let me solve this. 2) I've managed to figure out a way to parse through the data on EDICT (it's been three years), so I'm about 50% done to figuring out how to update the dictionary. Once that's done I might be able to solve the first issue.</div>
    <div>Unfortunately, I got swamped with work at my actual job so not much time. I think we've moved past that period now.</div>
    <h2>Change Log</h2>
    <h2>Reader</h2>
    <ul>
        <li>Added partial support for pronunciations (for exact matches)</li>
        <li>Fixed reading misattributions on exact matches with more than 1 reading</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/B7luArOaIl0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Projects',
        date: 'May 27, 2021',
        html: `
    <div>Made some QoL changes and designed a generic system for finding "related" books (see library).</div>
    <h2 class="marg-big">Change Log</h2>
    <h2>Reviews (!!!)</h2>
    <ul>
        <li><b>Autofocuses answer field on page load</b></li>
        <li><b>Autofocuses answer field after closing the edit modal</b></li>
        <li>Autofocuses reference word search on opening modal (if its been interacted with)</li>
    </ul>
    <h2>Library</h2>
    <ul>
        <li>Updated series tags for most books</li>
        <li><b>Added a badge indicating volume number. Clicking it will show books from that series</b></li>
        <li>Same added to modal</li>
        <li>Optimization: hover buttons removed on mobile</li>
        <li>Small performance adjustments</li>
    </ul>
    <h2>Reader</h2>
    <ul>
        <li>Modified the threshold system so that you receive a warning indicator instead of just locking you out completely</li>
        <li>Search syncs with reader when adding/deleting words</li>
        <li>Pinned card pagination now works properly</li>
    </ul>
    <div>Some books updates coming soon. I'm doing a double anime poll/release this month because I got absolutely annihilated by my job last couple of weeks.</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/B7luArOaIl0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Addressing Feedback',
        date: 'May 16, 2021',
        html: `
    <div>Addressing user feedback/bug fix requests</div>
    <h2 class="marg-big">Change Log</h2>
    <h2>Exporter</h2>
    <ul>
        <li>Added a mash all entries (similar to Floflo exporting)</li>
        <li>Removed separate rows option - there's no reason that a user needs access to data in that raw of a format. The exporter is for users.</li>
        <li>Options sidebars moved to left side to telegraph that you should adjust things first before generating datasets.</li>
    </ul>
    <h2>General System</h2>
    <ul>
        <li>You can now access the Reader and Vocabulary Lists without being logged in (go send your friends some books or something)</li>
        <li>Header: reader lights up on the reader page</li>
        <li>Header: reviews are greyed out when there are no reviews</li>
    </ul>
    <h2>Reader</h2>
    <ul>
        <li>Kanji that match a word in the book (ex: 私) will not have a "jump to" button</li>
        <li>Fixed trash/delete tooltips being the same</li>
        <li>When generating from link, an invalid "item" parameter will take you as far as it can</li>
    </ul>
    <h2>Books</h2>
    <ul>
        <li>Fixed "more info" button not working</li>
    </ul>
    <h2>Reviews</h2>
    <ul>
        <li>Now auto-focuses correctly</li>
    </ul>
    <media></media>    
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/Z53QxUZEjLw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Not General Update',
        date: 'May 9, 2021',
        html: `
    <div>A lot of stuff this week. I mentioned on the Wanikani thread that I have an acquaintance doing some work on statistics to boost work efficiency on this website, so this is the first update that involves using stats to drive development in any way.</div>
    <div>Anyway, I do think that this "adding additional volumes" thing will not be happening frequently, because a later stat report the dude compiled showed that book club books have about 400% more engagement on average, therefore I should make adding book club books the primary effort of the site.</div>
    <h2 class="marg-big">Change Log</h2>
    <h2>Books</h2>
    <ul>
        <li>Released a batch of second novels for series based on usage data (stats!!!)</li>
        <li>Konosuba 2</li>
        <li>Bakemonogatari 2</li>
        <li>Otome Game no Hametsu Flag 2</li>
        <li>Tenseishita Daiseijo 2</li>
        <li>And also the Ryuugajou Nanana side story because this is my website and I can do whatever I want</li>
    </ul>
    <h2>Vocab Lists</h2>
    <ul>
        <li>Minimum quality filter no longer applies to exact matches (fixes missing words from Yuru Camp, etc.)</li>
        <li>Recounted book unique words</li>
        <li>Fixed definitions with é appearing garbled</li>
    </ul>
    <h2>Reader</h2>
    <ul>
        <li>Added pagination to the bottom</li>
        <li>Added some options saving</li>
        <li>Added wk/freq thresholds to options to help filter out what words you should be adding vs just looking up</li>
        <li>Added lang="jp" to words so that it doesn't appear as mixed Chinese-Japanese</li>
        <li>Search: deleting/trashing works properly</li>
        <li>Search: deleting from search will sync with list</li>
    </ul>
    <h2>Reviews</h2>
    <ul>
        <li>Fixed certain mixed words breaking (ゲーム機) etc.</li>
    </ul>
    <media></media>    
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/gFVC0p4lknw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'General Update',
        date: 'May 2, 2021',
        html: `
    <h2>Change Log</h2>
    <ul>
        <li>Fixed A Certain Scientific Railgun List</li>
        <li><b>Exporter</b>: Now supports exporting books specifically (with freq/wk options)</li>
        <li><b>Reader</b>: Has a to top button and saves progress when switching from pinned to not pinned</li>
    <media></media>    
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/ady--PNMsfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'New Feature: The Reader',
        date: 'April 24, 2021',
        html: `
    <div>I've added a new Reader page to the website: you can access it by clicking the open book at the top of the page.</div>
    <div>The reader page lets you go through the vocabulary list of a book while you're reading it and includes handy featuers like access to the search functionality, custom word functionality, as well as granting standard features such as the ability to add/trash words.</div>
    <div>There are probably plenty of bugs in it! Still, I think it should useful. <a hef="#/reader">Here's a link</a> and don't forget to report any bugs you find.</div>
    <h2 class="marg-big">Change Log</h2>
    <h2 class="marg-big">Reader added</h2>
    <h2 class="marg-big">Reviews</h2>
    <ul>
        <li># of items in Schedule should be synced to the nearest hour (if it's 8:23 then 9:00)</li>
        <li>Manually mark answers as wrong with with (2) or by clicking the x on mobile</li>
        <li>reference word search field now clears before adding</li>
    </ul>
    <h2 class="marg-big">General</h2>
    <ul>
        <li>Added a progress bar until next xp level</li>
        <li>Highlighted progress towards next xp level done within the last 12 hours</li>
    </ul>
    <media></media>    
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/ady--PNMsfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'General Update',
        date: 'April 10, 2021',
        html: `
    <h2>Fixes</h2>
    <ul>
        <li>SRS: Fixed lightning mode marking question wrong if you instantly hit enter after completing a question</li>
        <li>Search: Now converts romaji for double n properly mid-word. Basically senni properly transcribes to せんい (繊維) instead of せんに</li>
    </ul>
    <h2>Exporter Changes</h2>
    <ul>
        <li>You can now mark lessons as known, enabling you to personalize future vocabulary lists by preventing repeat word exports.</li>
    </ul>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/cganW8kwG-s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'New Books',
        date: 'April 4, 2021',
        html: `
    <div>Just a standard book update for this week. Mostly working on redirecting Floflo stuff right now. Hopefully there will be some more exciting developments next week!</div>
    <div>In the meantime, there are eight new releases for this month coming out over the next couple of days.</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/HN2qtD_ws0E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Server-side Settings',
        date: 'March 28, 2021',
        html: `
    <h2>Options</h2>
    <div>
    Added server-side options. So most setting should be saved between devices now, except for some stuff like color theme (which needs to be applied at page load, not after). As usual contact me on Wanikani if you have any issues.
    </div>
    <h2>Additional Books Fixes</h2>
    <div>
    So after the discovery of the book issues last week, I wrote a test to find any similar books. I've found ten, and they are now fixed and re-released. Affected books include:
    <ul>
        <li>Sailor Moon Crystal</li>
        <li>Yuru Yuri</li>
        <li>Carole and Tuesday</li>
        <li>Ookami to Koushinryou 2</li>
        <li>Tokimeki Toshokan I</li>
        <li>Tenohira no Shousetsu</li>
        <li>Re Zero Kara Hajimeru 3</li>
        <li>Ojousama Tantei Arisu 1</li>
    </div>
    <h2>Custom Orders</h2>
    <div>
    People have been asking me for custom order again, which is weird because nobody asked me for a long time and now they are. I've included a thing on the side bar of the library page about if other people want custom orders.
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/UjERYchpESk?t=12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Book fixes',
        date: 'March 20, 2021',
        html: `
    
    <div>
        Rereleased some broke ass books: Konya Sekai Kara Koi ga Kietemo, Japanese Graded Readers Level 0 Volume 0, Yagate Kimi ni Naru Saeki Sayaka ni Tsuite 2/3. I used the wrong parser on them and it auto-converted hiragana words to kanji (ex: いる -> 鋳る) which is kinda not it, especially since I busted my anus thinking up a system to handle kana words.
    </div>
    <div>
        Also released Honzuki no Gekokujou, which was a private order, as I have a policy of making book club books available to multiple people because it encourages them to use the site together.
    </div>
    <div>
        Also also, could someone please tell youtube-kun that just because I listen to Japanese music doesn't mean that I want to hear Plastic Love literally every time I turn autoplay on?
    </div>
    <div>
        Anyway, there's a basic version of a grid system for quickly adding words right now. Final version will probably include jumping to words, searching, and whatever.
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/UjERYchpESk?t=12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Progress Tracker and Others',
        date: 'March 14, 2021',
        html: `
    <div>
        <h2>Change Log</h2>
        <br>
        <h2>Home</h2>
        <ul>
            <li>Now shows some tutorial messages directing you to the library page or the <a href="#/importer">import words page</a></li>
        </ul>
        <h2>Library</h2>
        <ul>
            <li><b>Unknown words are now counted upon entering the site for the first time</b> (make sure to set your wanikani level by going to the options menu ON THE LIBRARY PAGE)</li>
        </ul>
        <h2>Reviews</h2>
        <ul>
            <li>Post-review screen now shows progress through words instead of your accuracy (because you can see your accuracy behind the modal anyway)</li>
            <li>Post-review screen now changes icons to show when you'll be returning to the start</li>
            <li>Post review screen now shows if the card was a pass/fail</li>
        </ul>
        <h2>New Anime</h2>
        <ul>
            <li>Yuru Camp S2</li>
            <li>Mushoku Tensei</li>
            <li>Horimiya</li>
            <li>Gotoubun no Hanayome</li>
        </ul>
        <h2>New Books</h2>
        <ul>
            <li>Seiyuu Rajio no Uraomote</li>
            <li>Dantoudai to kakumeihime</li>
            <li>Ryuugajou Nanana no Maizoukin 2</li>
            <li>Isekai Picnic</li>
        </ul>
        <h2>Floflo End of Service</h2>
        <div>Floflo is shutting down. Transfers are scheduled for March 14/21/28. Transfer your data or it will be gone.</div>
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/gPvXlL76oUM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'General Update',
        date: 'February 27, 2021',
        html: `
    <div>
    <h2>Tutorials</h2>
        <ul>
            <li>Added tutorial notifications to every screen. These can be turned off</li>
            <li>"Go to vocab list" button on library page now glows to direct new users where to go</li>
            <li>Fixed search breaking with items that had special characters [] or () such as anime</li>
        </ul>
    <h2>Lessons</h2>
        <ul>
            <li><b>Fixed search not showing extra characters when there was a pronunciation available</b></li>
            <li>Card editor now aligns readings to left and definitions to right. Additional parts of speech are displayed on next line.</li>
        </ul>
    <h2>Lists</h2>
        <ul>
            <li>Removed streamlined cards from options. Replaced with tutorials, which I think makes a much better first impression.</li>
        </ul>
    <h2>Header</h2>
        <ul>
            <li>Removed options icon and shoved stuff in the dropdown menu</li>
            <li>Added a basic xp counter. Maybe final maybe not. Kind of just wanted it in before I forgot about it.</li>
            <li>Fixed an xp counter bug.</li>
        </ul>
    <h2>General</h2>
        <ul>
            <li>Private policy now includes a link to the wanikani discussion thread</li>
        </ul>
    <h2>Another Book Club Update</h2>
    <div>Currently pushing manga stuff into a excel spread sheet to see if I can put book club manga up</div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/Rv1B1ot5tSU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
            },
        ]
    },
    {
        title: 'Library Improvements',
        date: 'February 10, 2021',
        html: `
    <div class="row">
        <div class="col-8">
            <div>Made some adjustments to the library page. There's now a gear icon next to search where you can set some additional filters/options on.</div>
            <h2>Change Log</h2>
                <ul>
                    <li>Created an additional modal for search options</li>
                    <li>Added an option for viewing romaji/JP titles</li>
                    <li><b>Clicking the "read" book button under favorites now toggles between read/not-read/default</b></li>
                    <li>Added filters for free books (Aozora) and children's books</li>
                </ul>
            <div>I can already tell that the above filters will be a pain in the ass to maintain but that's a problem for future me. Will be catching up on anime uploads soon.</div>
        </div>
        <div class="col"><media></media></div>
    </div>
    <h2>Book Clubs</h2>
    <div>I'm currently trying to catch up with Wanikani's book clubs, which is something I used to do but have fallen behind on. <b>There is now a filter (in the aforementioned modal) which will cause the library page to only show Wanikani book club books.</b> This might be useful if you want to pick a book and be able to follow with discussions.
    </div> 
    <media></media>
    `,
        media: [
            { val: `<img src="https://i.gyazo.com/c59c817a43a8c09af5585844364d6eff.png"></img>` },
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/LlcpOAbL2x0?t=12" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Taking a break from weeb music"
            },
        ]
    },
    {
        title: 'Open Forum & Importer Improvements Maybe',
        date: 'February 2, 2021',
        html: `
    <div>
        Just implementing some importer improvements. It now uploads words in batches so there's should be a significantly reduced chance of it telling you the payload was too large.
    </div>
    <h2>Discussion Thread Reopened</h2>
    <div>
        You can now talk to me on Wanikani again. The discussion thread is open <a href="https://community.wanikani.com/t/koohicafe-the-other-wk-friendly-srs/48703/">here</a>. I've added a link to the top bar as well. Feel free to report bugs or complain or whatever. I realize that it's annoying to add someone on Discord just to report a bug, so this should help some people.
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/zLak0dxBKpM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Progress Update',
        date: 'January 22, 2021',
        html: `
    <div>
    There's been a general update. I just added the novels for the month. The anime should come soon.
    </div>
    <div>
    Progress on the backend continues. I've re-set up a generalized parser and am creating an interface for parsing small texts and assembling a list on the spot. There's also been some other, small-scale updates which you can find in the changelog.
    </div>
    <div>
        <h2>Changelog</h2>
        <ul>
            <li>Fixed reference words not showing during reviews</li>
            <li>Added an autocomplete/search functionality to reference words, making it easier to add them</li>
            <li>Modified and improved some aspects of the exporter</li>
            <li>Fixed exporter not returning correct amount of active words</li>
            <li>Other things I forgot about</li>
        </ul>
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/qN7S6grmsfg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Backend Update Part 1',
        date: 'January 14, 2021',
        html: `
    <div>
    Congratulations everyone on surviving The Worst Year. May there be many more to come.
    </div>
    <div>I've begun laying down the foundations for the backend of the rework. The API is much cleaner and easier to work with. Maybe a little bit slower. Message me if there's an issue. I tried to test everything though.
    </div>
    <div><strong>Two new pages are up: a user data <a href="#/exporter" underline>Exporter</a> as well as a known word <a href="#/importer" underline>Importer</a>.</strong> There are some changes to the Floflo version of these, mostly that these have much more fine-tuned control options such as the ability to enable/disable fields, etc. <strong>I haven't tested them super thoroughly but feel free to use them.</strong> Also remember to contact me on discord @<strong>raionus#8576</strong> if you have an issue.
    </div>
    <div>This update was supposed to come more than a week ago, but I ran into some extremely weird bugs where Bootstrap's modals just suddenly stopped working like they were supposed to. I had to upgrade to Bootstrap 5, which came with its own set of things to learn about/fix on the website. Hopefully it should all be good. Probably though, the website will catch fire again.
    </div>
    <div>
        <h2 class="marg-big">Change Log</h2>
        <h2>Core Changes</h2>
        <ul>
            <li>Updated to Bootstrap 5 & recoded all instances of modals</li>
            <li>Rewrote almost every API endpoint</li>
            <li>Added a new menu that gives access to additional pages (including data)</li>
            <li>Added <a href="#/exporter" underline>an exporter</a></li>
            <li>Added <a href="#/importer" underline>an importer</a></li>
            <li>Updated the book selector for vocabulary lists</li>
            <li>When adding a custom word, reading field is now automatically converted to kana</li>
        </ul>
        <h2>Fixes</h2>
        <ul>
            <li>Fixed the SRS post-review accuracy bar not filling up all the way</li>
            <li>Fixed search showing terms twice</li>
            <li>Fixed toggling off the last reading of an item, then reenabling it could leading to unexpected results</li>
            <li>Fixed last synonym from custom word being removable</li>
        </ul>
    </div>
    <div>
    I also recently logged in to save Floflo from eating itself, mostly just for the people who use it for the book exporter. I haven't and don't have any plans to continue supporting it, however.
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/2b3j3mwA1ZI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Merry Kwiss',
        date: 'December 25, 2020',
        html: `
    <div>
    Have a nice holiday. Get drunk or something.
    </div>
    <media></media>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/Jrl4bd0vkng" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Hot fixes',
        date: 'December 18, 2020',
        html: `
    <div class="c-red">See prev post for code refactoring</div>
    <div>
    Incomplete list of hotfixes for the recent code refactoring. Gonna save the WK thread til after Christmas so I can have a decent holiday.
    </div>
    </div>
    <span class="c-red">If you're having trouble with ignoring words you can look into resetting your local storage for the Koohi (should be a simple google search)</span>
    </div>
    <h2>General</h2>
    <div>
        <ul>
            <li>Readded favicon/page titles/descriptions</li>
            <li>Fixed username not appearing on Data page</li>
            <li>Fixed some erroring with the manual logout button</li>
        </ul>
    </div>
    <h2>Books</h2>
    <div>
        <ul>
            <li>Added ~60 missing covers</li>
            <li>Changed # of rows loaded at once to reduce server load</li>
        </ul>
    </div>
    <h2>Vocabulary Lists</h2>
    <div>
        <ul>
            <li>Fixed books with missing covers not rendering (and being unremovable)</li>
            <li>Ignore counter should now work properly</li>
        </ul>
    </div>
    <h2>Lessons</h2>
    <div>
        <ul>
            <li>Fixed pagination arrows not appearing properly at certain intervals</li>
            <li>Deleting/Trashing the final card on a page will set you back a page</li>
            <li>Removed misaligned box when user has no items to learn</li>
            <li>Adding kanji from search should work properly</li>
            <li>Fixed searched words not having their readings added correctly</li>
            <li>Existing items that were affected by above issue had their readings set to none</li>
            <li>Fixed some pagination errors</li>
            <li>Added loading indicator for lessons, fixed misalignment when no cards</li>
        </ul>
    </div>
    <h2>Reviews</h2>
    <div>
        <ul>
            <li>Disabling scoring until the cause of server fire can be confirmed</li>
        </ul>
    </div>
    <media></media>
    <h2>Known Issues</h2>
    <div>
        <ul>
            <li>Lessons api loading time is whack</li>
        </ul>
    </div>
    <div>
    `,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/GK2c4HBFC7I" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Rework Pt. 1 Change Log',
        date: 'December 18, 2020',
        html: `
<div>
I've just uploaded the first part of the rework. I'll be working on addressing any lethal bugs and sources of stability over the next few days. Y'all can expect some roughness in the meantime. I have some things in mind that should help with the roughness that occurs when loading the lessons page, although we'll have to wait and see if it works.
</div>
<div>
Incomplete list of changes for the rework.
</div>
<h2>General</h2>
<div>
    <ul>
        <li>No refresh times between pages</li>
        <li>Standardized button styling</li>
        <li>Modified modals to have a max body size</li>
        <li>Various color fixes on dark/light mode</li>
    </ul>
</div>
<h2>Books</h2>
<div>
    <ul>
        <li>Added datalist element to search (autocompletes, etc.)</li>
        <li>Modified sorting from searched terms</li>
    </ul>
</div>
<h2>Vocabulary Lists</h2>
<div>
        <ul>
            <li>New option: Auto-generate list at start</li>
            <li>Reworked parts of speech (separated into color categories based on usefulness for conjugation/usage)</li>
            <li>Various mobile improvements (sidebar relegated to modal, navigation near top, etc.)</li>
        </ul>
</div>
<h2>Lessons</h2>
<div>
    <ul>
        <li>Modified search bar</li>
        <li>Search now includes pronunciations</li>
        <li>Custom word creation gives reactive warnings while typing</li>
        <li>Improved color consistency (active items, vocab/kanji, etc.)</li>
        <li>Updating notes no longer fails when too long</li>
        <li>Improved cursor cues</li>
        <li>Example sentences prefer kanji when possible (reduces sentence mismatches)</li>
        <li>Dynamic pagination based on screen size</li>
        <li>Modified pre-quiz layout</li>
    </ul>
</div>
<h2>Reviews</h2>
<div>
    <ul>
        <li>Layout (depth, etc.)</li>
        <li>Clarity improvements (enlarged text, bold kanji removal)</li>
        <li>Reference words now work for definition questions (instead of just readings)</li>
        <li>Added buttons for undo/synonyms/modals for mobile</li>
        <li>Improved example sentence readability</li>
        <li>Improve visuals for notes</li>
    </ul>
</div>
<media></media>
<div>
<div class="c-red">
After the release of the rework, I'll open up a WK thread to do bug fixes while adding the next batch of vocabulary lists.
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/ceKkCXkjfMU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Final Rework Progress Update',
        date: 'December 14',
        html: `
<div>
Yeah, I don't know what happened. Time flies in quaratine. Anyway, I've split up the update between a back end update and a front end one. The front end will be coming out very soon (tm). I've fixed what I think are the last bugs, so all that's left is to do the terrible, terrible work of trying to get things done on the (live) linux server, because I'm a fucking ape and I don't understand how to get anything done without a GUI. I need to figure out how the hell to update Node and then I just need to make a new server block so that I can test the website. None of this is a lot of work but somehow it will probably take me three days.
</div>
<media></media>
<div>
On the bright side, I for once made the bright decision of releasing the new books/anime before trying to push the update out. Wow so えらい.
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/ceKkCXkjfMU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Development Update Again',
        date: 'November 25',
        html: `
<div>
Continuing to work on the code refactoring. Basic functionality is done for every single page on the website now. However, I'm still working on fixing stuff like visual page styling bugs (some new, some old) as well as making improvements to a lot of the pages (loading indicators, etc.). Among this includes stuff like standardizing things like button styling, restyling things like input fields, parts of the SRS, etc. I've been looking at old Floflo for some inspiration and stuff has come out of it like making the SRS look nicer through changes such as larger font sizes, gradients, etc.
</div>
<media></media>
<div>
The update could be out in a week, assuming everything goes smoothly, which it will not.
</div>
<div>
I also recently found this website on Wanikani called <a href="https://www.tsuginoji.com/" style="text-decoration:underline;">Tsuginoji</a>. It's a J>J AJAX dictionary with pitch accents. Very nice.
</div>
<div>
Transfers
    <ul>
        <li class="c-red">Last transfer: 11-23</li>
        <li class="c-red">Next transfer: 11-30</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/5X8WDBEc_PE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Development Update',
        date: 'November 18',
        html: `
<div>
Work is continuing on the code refactoring. I'm done with all the main features available right now on Koohi except for the review page, which is obviously the most complex. I've posted about this on Patreon already, but next I'll be trying to integrate Patreon into Koohi so that I can get out an export page and get rid of Floflo altogether.
</div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 11-15</li>
        <li class="c-red">Next transfer: 11-22</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/lKEqU2itOiw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'It\'s Dev Time Bois',
        date: 'November 2',
        html: `
<div>
    Hey, what's up. So I took an unexpected 10 days off because I hitched a ride back to my hometown. This was purely coincidental so I didn't get a chance to work ahead. Over the next few days, I'll catch up on stuff. Transfers are done. New anime books are done. Will put up the polls on Patreon.
</div>
<h2>Refactoring</h2>
<div>
    Aside from that, I'm in full dev mode at the moment. It's been 10 months or so since I started work on Koohi, so that means it's time for that yearly code refactoring. I'm porting Koohi's front end from a Express-EJS-Vue chimera into a full-fledged Vue single page application.
    <br>
    I'm currently done with the front page, posts, books page, and working on vocabulary list page right now. This leaves the lessons page, SRS, data page to go.
    <br>
    Once I'm done with that I might port it from being full Vue SPA to a React app. I'm not sure whether I'll release the Vue or the React version.
</div>
<div>
    If you're wondering what the actual benefits to this are: it depends what version I release. A Vue single page app would be self-explanatory - it's a single page app so there's literally no loading times now.
    <br>
    If it's in React, idk. One of the biggest benefits of React is that it's easily portable to mobile, so you could see a native mobile app, rather than a web page. We'll see though.
    <br>
    Either way: the main benefits are that it becomes extremely easy to modify website functionality because the individual parts of each page are split into easily modifiable components. It's not *hard* to do this right now, but it's kind of hit the point where I waste a lot of time looking for different files scattered around different folders and it makes it annoying to change things.
</div>
<h2>Extensions?</h2>
<div>
    The other thing I want to do is open up the dictionary part of the API to requests from anywhere. The end goal would be a Chrome (or something) extension that allows you to query the API to parse short sentences or single words, see the definitions, and then add a word to Koohi from anywhere on the internet.
    <br>
    The functionality would be comparable to something like Yomi-chan, but you're free to look things up (as opposed to having to select something on the page), and obviously it would create flashcards for Koohi, not Anki.
</div>
<div>
    Anyway, just wanted to mention that we're not on maintenance mode right now. Thanks for everyone who's contributing on Patreon and putting up with my lateness this month.
</div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 11-3</li>
        <li class="c-red">Next transfer: 11-14</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/qPllDPnDsX8?list=WL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: ""
            },
        ]
    },
    {
        title: 'Floflo Imports',
        date: 'October 10',
        html: `
<div>Did a Floflo import. Starting work on the Hololive thing again. Job hunting in the US is a bitch.</div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 10-10</li>
        <li class="c-red">Next transfer: 10-17</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/b9mHKXR9Opw?list=WL" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'Minor Fixes',
        date: 'October 2',
        html: `
<div>Fixed an issue with reference words not deleting correctly.</div>
<div>Prev/Next buttons now align correctly on the pre-quiz modal.</div>
<div>Deleting/trashing mid-review/lesson now correctly moves you to the next question.</div>
<div>If you're having issues message me on Discord <span underline>raionus#8576</span></div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 9-26</li>
        <li class="c-red">Next transfer: 10-4</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/rT0hDTsNais" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'General Update',
        date: 'September 26',
        html: `
<div>Added new anime lists. Fixed an error with creating an account that would say "internal server error" even when the account creation was successful.</div>
<div>If you're having issues message me on Discord <span underline>raionus#8576</span></div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 9-26</li>
        <li class="c-red">Next transfer: 10-4</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/rT0hDTsNais" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'Login issues fixed & Catching up',
        date: 'September 23',
        html: `
<div>Fixed login issues a few days ago and waited a bit to make sure they were gone. Seems to be the case now, so we're good.</div>
<div>Books have been added, will be getting around to transfers plus Patreon polls in a bit.</div>
<div>If you're having issues message me on Discord <span underline>raionus#8576</span></div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 9-11</li>
        <li class="c-red">Next transfer: <s>9-21</s>9-24</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/rT0hDTsNais" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'Update Attempt 2',
        date: 'September 12',
        html: `
<h2>I'm aware of the issues with users getting logged out. Give me a day or two to figure it out. Content updates will be delayed in the meantime. Apologies for screwing the pooch twice.</h2>
<div>Trying to push out the update that failed last time. This should hopefully be introducing refresh tokens, meaning that you shouldn't have to login again anymore. <b>Your old refresh token needs to go away for this to work, so you may need to logout manually, or you could just let your old access token expire.</b>
<div>Aside from that, the update features a couple new changes to the interface. Most notably, you should be able to delete/trash items directly from the card editor. This works during reviews and in the lessons page.</div>
<div>If you're having issues message me on Discord <span underline>raionus#8576</span></div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 9-11</li>
        <li class="c-red">Next transfer: 9-21</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/3zh9Wb1KuW8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'General Update',
        date: 'August 23, 2020',
        html: `
<div>Not a whole lot going on. Finally finished up the landing page. Added the ability to see your last 200 or so last reviewed items on the lessons page. Mostly I was just transferring my dev environment from my broke laptop to my new desktop.</div>
<div>
<media></media>
Transfers
    <ul>
        <li class="c-red">Last transfer: 8-9</li>
        <li class="c-red">Next transfer: 8-24</li>
    </ul>
</div>
`,
        media: [
            {
                val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/sS99NE4tmPQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
                caption: "Inserting song due to lack of substance."
            },
        ]
    },
    {
        title: 'General Update',
        date: 'August 5, 2020',
        html: `
<h2>Anime, Patreon, Transfers</h2>
<div>No, I haven't forgotten. I literally just finished my summer finals and have officially graduated from college. So yes, this week I'll be catching up on those things. Patreon will have two anime polls because I didn't get around to it last month since the poll from the previous month bled into the next month. I also just put up like 20 books so I wasn't super worried about content updates.</div>
<media></media>
<h2>Refactoring Code</h2>
<div>
Boring stuff, I know. Then again, it's this boring stuff that made me have to remake the website from the ground-up in the first place.
<br /><br />
I've been refactoring code some disorganized code so that it's a little less gross. This included stuff like turning the card editor into a reusable component so that it's standardized across pages. If you thought it was already standardized, haha. Fooled you. I had just copied and pasted it on the SRS page and changed some values.
<br /><br />
Anyway, this had led to some improvements overall, such as the ability to have example sentences appear in the card editor, as is now the case in the lessons page.
</div>
<h2>Corpus Improvements</h2>
<div>
Refactoring has also led to some improvements to the corpus. I have most of an administration system set up, so that includes the ability to do stuff like delete books through an API without needing to whip out the entire command line every time. I've added a few more books. I'm not really sure when I'm supposed to stop adding books, because each row makes the database slightly slower - and to what end?
<br /><br />
I suppose the end doesn't matter, since I managed to speed up example sentences about three times. So it can still get bigger, but I'm not well-versed in managing huge ass databases.
</div>
<h2>Next stuff</h2>
<div>
I was extremely dissatisfied with the way Dungeon ni Deai kept popping up in the example sentences with long-ass paragraphs. I'm currently looking at systems to start to discourage overly-complicated texts. I've settled on implementing readability tests into the example sentence process. Basically, an entire book gets read and it gets a score which influences the likelyhood of sentences for that book appearing.
<br /><br />
I was given the choice of using Hayashi or Obi-2. Hayashi has some issues because it uses sentence length as a factor, which is an issue because more complex sentences are more likely to use kanji, so that gets skewed. Obi-2 looked like a better choice, but when put into practice it ended up producing very similar scores for novels, making it actually useless for my purposes.
<br /><br />
Anyway, I've implemented this readability test into the corpus, and I'm thinking of integrating readability scores into the library page, so that people can have a better idea of how hard a book actually is, since the current difficulty determinant of total words doesn't take length or sentence structure into account. I'm also thinking of setting up a Discord server soon. Not because I want one but because I know adding me on as a contact on Discord might be more than someone who wants to report a bug wants to go through.
<br /><br />
Also, I'm like out of ideas for what to work on, and I don't want to work on things for the sake of it. So I might just start focusing on testing different browsers/devices and try and officially release soon.
</div>
Transfers
    <ul>
        <li class="c-red">Last transfer: 7-19</li>
        <li class="c-red">Next transfer: 8-9</li>
    </ul>
</div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/9j5AiZS6wKo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` },
        ]
    },
    {
        title: 'Revamping the List Page',
        date: 'July 25, 2020',
        html: `
<div>It's been a productive week, for whatever reason. I think a lot of the mental pressure is off since closing the Wanikani thread so that leaves me free to actual think about coding. Anyway, I spent some time improving existing code so that it's a bit more robust. I made a lot of visual improvements to the vocabulary lists in particular, so that should feel better.</div>
<media></media>
<h2>Vocabulary Lists</h2>
<ul>
    <li>Completely redid list reordering buttons - they should be less visually intrusive and accurately reflect the nature of the list they'll create.</li>
    <li>Thickened thin buttons and selectors such as the navigation to facilitate ease of use</li>
    <li>The header when selecting books is now contextual, reflecting the ability to add multiple books, etc.</li>
    <li>Primary book is shown alongside benchmarks so that you can be sure of what kind of list you made</li>
    <li><b>An Ignore feature has been added.</b> This is done on a per-book basis and data is stored locally.</li>
    <li><b>You can now undo any action.</b> The one exception for this is phonetic matches, which will restore the word to the list but not remove any of the additional flashcards created.</li>
</ul>
<h2>Deleting Items</h2>
<div>
    In addition, some huge changes have been made to the way deleting items from the lessons page. Before, it'd leave a lot of trash behind on the database such as reference words. However, it should now 1) delete the card 2) restore the reading to lists (if it was a phonetic match) 3) kill any linked reference words. Of course, this was created from scratch so there may be some issues.
</div>
<h2>Contact me</h2>
<div>I've added my discord information to the about section which can be found through the options <i class="fas fa-cog"></i> menu. If you have an issue or want to report a bug, feel free to message me. I'm not currently interested in feature requests.</div>
<div class="col-8 mx-auto">
    <media></media>
</div>
<h2>Polls & New Books</h2>
<div>I literally added ten new books like two weeks ago so I didn't bust ass to get new things up this week. The new anime that were voted on Patreon will in the next update, and a new poll should be added soon.</div>
<div>
Transfers
    <ul>
        <li class="c-red">Last transfer: 7-19</li>
        <li class="c-red">Next transfer: 7-26</li>
    </ul>
</div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/FAj5q0J3nHw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` },
            { val: `<img src="https://i.gyazo.com/e8c70e6828aa05fefe64e6bcc2a314ca.png" />` },
        ]
    },
    {
        title: 'General Updates pt. 3',
        date: 'July 20, 2020',
        html: `
<div>A lot of grunt work this week. <b>Added somewhere between 400-500 rows of meta data like descriptions and info links to books as well as missing images.</b> Of 160 items with missing data, about 130 have been handled. The information should be fairly dependable now, although there's still some more works to get around to.</div>
<h2>QoL & Trimming</h2>
<div>A few quality of life changes were added. This includes stuff like trimming and other small changes. One of these, you'll see eventually, is an auto-refresh when you log in. So that'll be less annoying. <b>You can access trimming from the options menu <i class="fas fa-cog"></i></b></div>
<media></media>
<h2>Regarding Transfers</h2>
<div><b>Since trimming now exists, you can clean out your account. This means that if you've been using Floflo instead of Koohi you can clear your account so that a second transfer to Koohi comes out clean.</b></div>
<div>There were not many transfers but I got several requests from people who didn't have anything show up on Floflo. This is just a reminder - due to security concerns, I will not be transfering data if your email and usernames don't match what was used on Floflo. If you got nothing from the transfer you probably fall in the aforementioned category of people who didn't sign up with the same info.</div>
<div>
Transfers
    <ul>
        <li class="c-red">Last transfer: 7-19</li>
        <li class="c-red">Next transfer: 7-26</li>
    </ul>
</div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/NO66dNMwmbU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>` },
        ]
    },
    {
        title: 'General Updates pt. 2',
        date: 'July 9, 2020',
        html: `
<div>Setup some features for slow-dripping content. Books will be released 1 or 2 at a time. You can see future releases and their release dates, and bookmark them ahead of time if you're interested. I also made some administrative features, such as a personal page for tracking which books are missing stuff like descriptions and images. So that should help me get that done.</div>
    <div>
    Book fixes:
    <ul>
        <li>Ryuugajou Nanana</li>
        <li>Ore wo Suki nano wa Omae</li>
    </ul>
</div>
<div>
Transfers
    <ul>
        <li class="c-red">Last transfer: 7-9</li>
        <li class="c-red">Next transfer: 7-19</li>
    </ul>
</div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/QrFWWl_PDK8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, caption: 'Suffer.' },
        ]
    },
    {
        title: 'General Updates',
        date: 'July 1, 2020',
        html: `
<div>
    Working on general website stuff like a <a href="/policy" underline>private policy</a> page (required for setting up email sending through Google) and a <a href="/welcome" underline>welcome/features</a> page. Aside from that I've started to prepare for mobile by implementing responsive design on pages. There's still a need to check out if the event triggers are differing on mobile and if anything like that needs to be fixed.
</div>
<div>
    Other fixes include changing up update post styling so that it's easier to read, fixing some firefox bugs (such as Wanakana not converting japanese properly), and disabling Kino's Journey Volume 1 on the corpus due to improperly parsed data from Flowers showing up under the same name.
</div>
<div>
    I've closed down the thread on Wanikani. I'm not currently interested in developing in the spotlight anymore, and the thread isn't generating discussion that I'm interested in hearing right now. I will continue to develop and bug test on my own, which isn't much different because there were next to no posts actually assisting me in the process of debugging. Communication will occur through Floflo and Koohi for now. I will post my Discord username in the future, in case anyone wants to report a bug or something. Not right now though.
</div>
<div>
<li class="c-red">Last transfer: 6-21</li>
<li class="c-red">Next transfer: Undecided but within a week</li>
</ul></div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/QrFWWl_PDK8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, caption: 'Suffer.' },
        ]
    },
    {
        title: 'The Corpus Update',
        date: 'June 17, 2020',
        html: `
<div>So today marks the initial release of the Floflo Corpus. The corpus is just a really big database of novel/anime transcript parses. The initial release has about 20 books worth, covering about 26,000 unique words. As usual, transfers have been done as well.</div>
<div class="col-12 col-md-8 mx-auto"><media></media></div>
<div>I don't actually know what people will use the example sentences for (aside from the obvious stuff) so I'm just going to wait and see - respond to feedback and stuff.</div>
<div class="row">
    <div class="col-12 col-md-8">
        <div>In the SRS, example sentences will appear with a thumbs up and a thumbs down next to each. The thumbs up/down affects the sentence's score, which affects how often the sentence will appear for everyone. By default, it's set to thumbs up a sentence. This is fine, because thumbs downs carry three times the power, so if a sentence ever gets to the top that shouldn't be there, it can get vetoed pretty quickly. Anyway, we'll see how it goes.</div>
        <div>Aside from that, I've updated some miscellaneous stuff like improving the *layout* for most pages for mobile. I wouldn't call the mobile layout "good" but it's significantly less atrocious than before.</div>
    </div>
    <div class="col-12 col-md-4"><media></media></div>
</div>
<div><ul>
<li class="c-red">Last transfer: 6-21</li>
<li class="c-red">Next transfer: 6-28</li>
</ul></div>
`,
        media: [
            { val: `<iframe width="100%" height="315" src="https://www.youtube.com/embed/QrFWWl_PDK8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, caption: 'Suffer.' },
            { val: `<img src="https://i.gyazo.com/633ace7537386d8963ab2a5331ca41df.png" />`, caption: 'Mobile layout' }
        ]
    },
    {
        title: 'Transfer Complete 6-17',
        date: 'June 17, 2020',
        html: `
<div>What's up everyone. Long couple of weeks! My mouth is healed, and transfers are done as of 6-17.</div>
<div>This week we'll see the first version of the corpus come up. This means there will be example sentences for every single word that pops up on the SRS. There will be a scoring system so that bad sentences can be pushed out of the rotation.<br />If anyone is interested in providing feedback on that one on Wanikani, please do. It's one of those systems where I'll have to spend some actual time with it to see how it's best used.</div>
<div>New books/anime for the month should be coming up soonish.</div>
<div><ul>
<li class="c-red">Last transfer: 6-17</li>
<li class="c-red">Next transfer: 6-20</li>
</ul></div>
`,
        media: [
            { val: `<iframe src="https://www.youtube.com/embed/50wa4qBLE5o?start=20" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="height:315px;width:100%;"></iframe>`, caption: 'Blog post media test' }
        ]
    },
    {
        title: 'Transfer Complete 6-3',
        date: 'June 3, 2020',
        html: `
<div>Latest transfer complete. Next planned on 6/13 because I need tor recover from a surgery + do finals. No updates planned this week.</div>
<div>Last update was on 6/31. Check the previous blog post for info.</div>
`,
        media: [
            { val: `<iframe src="https://www.youtube.com/embed/50wa4qBLE5o?start=20" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="height:315px;width:100%;"></iframe>`, caption: 'Blog post media test' }
        ]
    },
    {
        title: 'Feedback Changes & Other Stuff',
        date: 'May 31, 2020',
        html: `
<div>Responding to what feedback I can this week. Not sure if I'll be able to work next week, as I'm getting my wisdom teeth removed so that's gonna take me out for like four days. Also finals.</div>
<media>
</media>
<div>Changes based on feedback</div>
<ul>
<li>Library - made load on scroll (instead of weird arrows)</li>
<li>Lessons - added a button to copy text (since hold click is reserved for multiple selection)</li>
<li>Lessons - added a max word length before elipses are used; will make cards more even in height</li>
<li>Lessons + SRS - can't remove last synonym for a word with no inherent definitions (ex: custom words)</li>
<li>SRS - Added lightning mode</li>
<li>Data - added a 'continue learning' button</li>
<li>Data - active items have been split in to active kanji and active words</li>
</ul>
<div>Other fixes</div>
<ul>
<li>Items should no longer fall back to SRS 0</li>
<li>User will receive a warning if item is unsolvable (ex: no synonyms or definitions)</li>
<li>Minor header improvements</li>
</ul>
<div>Transfer Status:
<ul><li>Last transfer: 5-24</li><li><strong>Next transfer: 5-31</strong></li></ul>
</div>
<div>Next projects:
<ul>
<li>Corpus is 'functional' but I'll need to integrate it into the SRS</li>
</ul>
</div>
`,
        media: [
            { val: `<iframe src="https://www.youtube.com/embed/50wa4qBLE5o?start=20" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="height:315px;width:100%;"></iframe>`, caption: 'Blog post media test' }
        ]
    },
    {
        title: 'Data distributions & Transfers Updated',
        date: 'May 21, 2020',
        html: `
<div>Just did some 4fun updates. There's now a 'distribution' section on the <a href="/data/" underline>data page</a>. It'll show what percent of the words you've learned the top three books account for. In addition there's also a distribution subsection for each book you've learned from, which will show what impact that book had on your overall learning.</div>
<media>
</media>
<div>Changes</div>
<ul>
<li>Added distributions to data page</li>
<li>You can now add kanji directly from the search bar on the lessons page</li>
</ul>
<div>Transfer Status:
<ul><li>Last transfer: <s>5-19</s>5-24</li><li>Next transfer: <s>5-24</s>5-27</li></ul>
</div>
<div>Next projects:
<ul>
<li>Email Server - necessary for password resets, verification emails, receipts, etc. I'm gonna hold this off until post-graduation because I need a solid chunk of time to understand how to set one up.</li>
<li>Corpus - I've successfully split apart a book so now there just comes the mass of data required for implementation.</li>
<li>Example Sentences - Once the corpus is done, I'll just need to ping the corpus for stuff. After that I'll need to set up a scoring system to filter out garbage sentences (ex: 滝です。)</li>
</ul>
</div>
`,
        media: [
            { val: `<iframe src="https://www.youtube.com/embed/jsIS3WiAzso" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="height:315px;width:100%;"></iframe>`, caption: 'Blog post media test' }
        ]
    },
    {
        title: `Status update`,
        date: `May 17, 2020`,
        html: `
<div>Made a lot of progress on the corpus this weekend. I imagine that an early version of it will be available as early as next weekend.</div>
<div>Fixed issues:
<ul>
    <li>Kun-yomi not working on kanji</li>
    <li>When adding kanji on the search bar (via custom words interface), kanji will all be deselected by default. This should help people who hoard hundreds of lessons.</li>
</ul>
</div>
<div>Transfer Status:
<ul><li>Last transfer: 5-11</li><li>Next transfer: <strike>5-15</strike> 5-18 (wireless card died and I got set back)</li></ul>
</div>
`
    },
    {
        title: `Transfer Completed 5-11`,
        date: `May 11, 2020`,
        html: `
<div>Self explanatory. Sorry that took so long. Next post is planned for 5-15</div>
`
    },
    {
        title: `Timer Modes & Late Transfers`,
        date: `May 7, 2020`,
        html: `
<div>Hey what's up everyone. School's been rough for the past week because midterms so I'm very behind schedule on transfers. Here's some updates though!</div>
<ul>
    <li><strong>Added 2 new wrap up (now timer) modes added to the SRS</strong></li>
    <li>My favorite is mode three, which sets a limit on how long you're allowed to take per question before it auto-submits. This helps you keep a good tempo.</li>
    <li><strong>Improved auxillary links for anime on the library page</strong></li>
    <li>Added support for because.moe on auxillary links - this should help you find where to (legally) watch anime for the lists on this website</li>
</ul>
<div><strong>To avoid things like this from happening in the future I will add a widget for when the last batch of transfer requests took place and when the next one is planned.</strong></div>
<div>My immediate plans are: 1) do transfer requests ASAP 2) add the books/anime from Patreon polls 3) Make a password reset function (this will take some time because I need to get an email server set up)</div>
`
    },
    {
        title: `Changes & Data Transfers`,
        date: `April 27, 2020`,
        html: `
<ul>
    <li>If there are no changes to your account then it means your email didnt match the one on Floflo. Contact me on Wanikani.</li>
    <li>Trashed words will start transferring over now</li>
    <li><strong>Next transfer sometime on April 1, PST</strong></li>
</ul>
`
    },
    {
        title: `Changes & Data Transfers`,
        date: `April 23, 2020`,
        html: `<ul>
    <li>Uploaded new covers & started adding meta data (descriptions, etc.) to books on the library page</li>
    <li>This includes things like descriptions, info links, purchase links, etc. Click a book on the library page to see</li>
    <li><strong>Status: ~80/230 done</strong></li>
    <li>Meta data for visual novels & anime completed</li>
    <li><strong>All transfers completed as of 4/23</strong></li>
    <li>If you didn't get your data transfered, it's because your email doesn't match Floflo. This was about 5 people who submitted requests - you can talk to me on Wanikani
        but yeah I have no idea who you are on Floflo, so...
    </li>
    <li>Various fixes over the past week go check <a href="https://trello.com/b/ZWBLIezb/flfl-recode-progress" style="text-decoration:underline;">trello</a></li>
</ul>`
    },
];

export default postListHTML
